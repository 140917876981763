<template>
  <div class="chat-ai">
    <div class="aside">
      <div class="new-btn" @click="addNew">
        <i class="el-icon-plus" style="margin-right: 10px"></i>
        新建对话
      </div>
      <div
        v-for="(item, index) in chat_list"
        :key="index"
        class="chatListItem"
        :class="{ selected: currentId === item._id.$id }"
        @click="selectItem(item)"
      >
        <i
          class="el-icon-chat-square"
          style="font-size: 18px; margin-right: 10px"
        ></i>
        <div v-if="!item.editStatus" class="txt">{{ item.name }}</div>
        <el-input
          v-model="item.name"
          v-if="item.editStatus"
          class="txt"
          @keyup.native.enter="changeName(item)"
        ></el-input>
        <i
          class="el-icon-edit edit-icon"
          @click.stop="item.editStatus = true"
          v-if="item.type !== 'coordinator'"
        ></i>
        <i
          class="el-icon-document-remove archive-icon"
          title="归档"
          @click.stop="archive(item)"
          v-if="item.type !== 'coordinator'"
        ></i>
        <i
          class="el-icon-delete del-icon"
          @click.stop="remove(item)"
          v-if="item.type !== 'coordinator'"
        ></i>
        <i
          class="el-icon-user set-icon"
          title="协作人"
          @click.stop="setCor(item)"
          v-if="item.type !== 'coordinator'"
        ></i>
      </div>
      <div style="text-align: center; margin-top: 10px">
        <el-button v-if="!showAll" @click="viewAll" type="primary"
          >显示全部</el-button
        >
      </div>
    </div>
    <div
      class="main"
      v-loading="loading"
      element-loading-text="AI正在处理，请稍后..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="topLine">
        <el-button @click="exportPDF" type="primary" size="medium"
          >导出成PDF</el-button
        >
        <span style="margin-left: 30px; margin-right: 10px">数据流模式</span
        ><el-switch v-model="isStream"></el-switch>
        <span style="margin-left: 30px; margin-right: 10px">选择模型</span>
        <el-select v-model="currentMode" size="medium" v-if="!currentItem.mode">
          <el-option value="GPT-4"></el-option>
          <el-option value="GPT-3.5"></el-option>
          <el-option value="DALL·E"></el-option>
          <el-option value="moonshot-v1-8k"></el-option>
          <el-option value="moonshot-v1-32k"></el-option>
          <el-option value="moonshot-v1-128k"></el-option>
          <el-option value="midjourney"></el-option>
          <el-option value="llama3"></el-option>
          <el-option value="leonardo.ai"></el-option>
          <el-option value="o1-preview"></el-option>
          <el-option value="o1-mini"></el-option>
        </el-select>
        <span v-if="currentItem.mode">{{ currentMode }}</span>
        <div v-if="currentMode === 'leonardo.ai'" style="margin-left: 10px">
          <el-select
            v-model="leoModel"
            style="width: 200px"
            placeholder="请选择模型"
          >
            <el-option
              v-for="(x, i) in leoardList"
              :key="i"
              :value="x.id"
              :label="x.name"
            ></el-option>
          </el-select>
          <el-select
            v-model="leoStyle"
            style="width: 200px; margin-left: 10px"
            placeholder="请选择"
          >
            <el-option
              v-for="x in leoardStyleList"
              :key="x"
              :value="x"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="messageContainer" id="messageContainer">
        <div
          v-for="(item, index) in currentMessageList"
          :key="index"
          class="messageItem"
          :class="{ grey: item.role === 'assistant' }"
        >
          <el-button
            class="editBtn"
            size="mini"
            @click="editContent(item, index)"
            >Edit</el-button
          >
          <el-button class="copyBtn" size="mini" @click="copyContent(item)"
            >Copy</el-button
          >
          <el-button
            class="removeBtn"
            size="mini"
            type="danger"
            @click="removeContent(index)"
            v-if="currentType !== 'coordinator'"
            >Remove</el-button
          >
          <div
            class="messageItem1"
            v-html="changeToHtml(item.content)"
            v-if="item.mode !== 'image'"
          ></div>
          <div class="messageItem1" v-if="item.mode === 'image'">
            <img :src="'data:image/png;base64,' + item.content" />
          </div>
          <div class="messageItem1" v-if="item.mode === 'imageMid'">
            <img style="max-width: 100%" :src="item.imgUrl" />
            <div class="imageEditBtn" v-if="item.taskId">
              <el-button
                size="small"
                @click="toEditImage('UPSCALE', 1, item.taskId)"
                >U1</el-button
              >
              <el-button
                size="small"
                @click="toEditImage('UPSCALE', 2, item.taskId)"
                >U2</el-button
              >
              <el-button
                size="small"
                @click="toEditImage('UPSCALE', 3, item.taskId)"
                >U3</el-button
              >
              <el-button
                size="small"
                @click="toEditImage('UPSCALE', 4, item.taskId)"
                >U4</el-button
              >
              <el-button
                size="small"
                @click="toEditImage('REROLL', null, item.taskId)"
              >
                <i class="el-icon-refresh"></i>
              </el-button>
            </div>
            <div class="imageEditBtn" v-if="item.taskId">
              <el-button
                size="small"
                @click="toEditImage('VARIATION', 1, item.taskId)"
                >V1</el-button
              >
              <el-button
                size="small"
                @click="toEditImage('VARIATION', 2, item.taskId)"
                >V2</el-button
              >
              <el-button
                size="small"
                @click="toEditImage('VARIATION', 3, item.taskId)"
                >V3</el-button
              >
              <el-button
                size="small"
                @click="toEditImage('VARIATION', 4, item.taskId)"
                >V4</el-button
              >
            </div>
          </div>
          <div class="messageItem1" v-if="item.mode === 'imageLeo'">
            <img
              v-for="(item, index) in item.imgList"
              :key="index"
              style="max-width: 100%"
              :src="item"
            />
          </div>
          <div
            v-if="item.mode === 'imageMid' && !item.imgUrl"
            class="messageItem1"
          >
            图片生成中...当前进度{{ progress }}
          </div>
          <div
            v-if="
              item.mode === 'imageLeo' &&
              (!item.imgList || item.imgList.length === 0)
            "
            class="messageItem1"
          >
            图片生成中...
          </div>
          <div
            v-if="
              !item.content &&
              item.mode !== 'imageMid' &&
              item.mode !== 'imageLeo'
            "
            class="messageItem1"
          >
            生成中...
          </div>
        </div>
      </div>
      <div class="inputC" v-if="currentId">
        <div class="inner">
          <div style="height: 40px; display: flex">
            <rtasr
              style="margin-right: 10px"
              @changeValue="changeText"
              @pending="(e) => doPending(1, e)"
              :disabled="pending === 2"
            ></rtasr>
            <rtasr1
              @pending="(e) => doPending(2, e)"
              :disabled="pending === 1"
              style="margin-right: 10px"
              @changeValue="changeText1"
              @saveTemple="saveTemple"
              @clearTemple="clearTemple"
            ></rtasr1>
            <div>
              <el-button
                @click="stopGenerate"
                type="primary"
                size="small"
                v-if="isGenerate"
                >Stop generating</el-button
              >
            </div>
          </div>
          <div style="display: flex; align-items: center">
            <el-input
              :autosize="{ minRows: 2, maxRows: 4 }"
              type="textarea"
              v-model="currentM"
              class="myInput"
              placeholder="请输入内容"
            ></el-input>
            <el-dropdown style="margin-left: 20px" @command="handleCommand">
              <el-button type="primary" @click="sendMessage()">
                发送<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="index"
                  v-for="(item, index) in prompts"
                  :key="index"
                  >{{ item.name }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              type="primary"
              @click="showPrompts = true"
              style="margin-left: 10px"
              >编辑</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="showD" title="设置协作人">
      <el-select
        v-model="form.coordinator_id"
        multiple
        placeholder="请选择协作人"
        filterable
      >
        <el-option
          v-for="item in users"
          :key="item._id.$id"
          :value="item._id.$id"
          :label="item.user_lastName + item.user_givenName"
        ></el-option>
      </el-select>
      <div style="margin-top: 10px">
        <el-button @click="confirmC" type="primary">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="showPrompts"
      title="编辑Prompts前缀"
      :close-on-click-modal="false"
    >
      <el-button @click="addPrompt" type="primary">添加Prompt</el-button>
      <div
        v-for="(item, index) in prompts"
        :key="index"
        style="margin-top: 10px"
      >
        <div style="margin-bottom: 10px">
          <el-input v-model="item.name"></el-input>
        </div>
        <div>
          <el-input v-model="item.content" type="textarea" :rows="4"></el-input>
        </div>
      </div>
      <div style="margin-top: 10px">
        <el-button @click="showPrompts = false" type="primary">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showContent">
      <el-input
        v-model="currentContentString"
        :rows="10"
        type="textarea"
      ></el-input>
      <div style="margin-top: 10px">
        <el-button @click="confirmEditContent" type="primary">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  sendConversation1,
  computeToken,
  getUserConversationList,
  getUserConversationListTen,
  updateConversation,
  removeConversation,
  getUserList,
  updateConversationCoordinator,
  sendConversation,
  initConversationName,
  sendConversationAndSave,
  getImage,
  setConversationMode,
  archiveConversation,
  getMidJourney,
  getMidJourneyResult,
  changeImage,
  getLeonardoAI,
  getLeonardoAIResult,
  getLeonardoModelList,
} from "../../api/assistant";
import { getUserId } from "../../utils/store";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import rtasr from "../../components/RealtimeTransfer.vue";
import rtasr1 from "../../components/RealtimeTransfer1.vue";
// markdown解析，代码高亮设置
window.marked.setOptions({
  highlight: function (code, language) {
    const validLanguage = window.hljs.getLanguage(language)
      ? language
      : "javascript";
    return window.hljs.highlight(code, { language: validLanguage }).value;
  },
});
export default {
  components: {
    rtasr,
    rtasr1,
  },
  data() {
    return {
      showAll: false,
      chat_list: [],
      currentId: "",
      currentM: "",
      currentItem: {},
      currentType: "",
      currentMessageList: [],
      templeWord: "",
      templeHtml: "",
      isGenerate: false,
      currentIndex: 0,
      users: [],
      showD: false,
      form: {
        coordinator_id: [],
        id: "",
      },
      isStream: true,
      loading: false,
      currentMode: "GPT-3.5",
      prompts: [
        {
          name: "生产可能学园文稿",
          content:
            "润色、简化和提升一下以下向小朋友介绍知识的文章，并在最后提供一道自测题（四个选项，有标准答案）：",
        },
        { name: "生产要点文稿", content: "" },
      ],
      showPrompts: false,
      currentContentString: "",
      showContent: false,
      currentContentIndex: 0,
      pending: 0,
      templeContent: "",
      progress: "0%",
      leoardList: [],
      leoModel: "b24e16ff-06e3-43eb-8d33-4416c2d75876",
      leoardStyleList: ["LEONARDO", "NONE"],
      leoStyle: "LEONARDO",
    };
  },
  mounted() {
    this.fetchData();
    this.getUsers();
    getLeonardoModelList().then((res) => {
      console.log(11111, res);
      this.leoardList = res.data.data.custom_models;
    });
    // sendConversation1({
    //         messages: [{ role: 'user', content: '你好' }]
    //       })
  },
  methods: {
    computeToken() {
      computeToken({
        messages: this.currentMessageList,
        model:
          this.currentMode === "GPT-4"
            ? "gpt-4"
            : this.currentMode === "GPT-3.5"
            ? "gpt-3.5-turbo"
            : this.currentMode,
      });
    },
    saveTemple() {
      this.templeContent = this.currentM;
    },
    clearTemple() {
      this.templeContent = "";
    },
    doPending(type, e) {
      if (e) {
        this.pending = type;
      } else {
        this.pending = 0;
      }
    },
    getUsers() {
      getUserList().then((res) => {
        this.users = res.data;
      });
    },
    refreshData(flag) {
      if (this.showAll) {
        getUserConversationList({
          user_id: getUserId(),
        }).then((res) => {
          if (res.data.code === 0) {
            this.chat_list = res.data.data.map((item) => {
              item.editStatus = false;
              return item;
            });
            if(flag){
              this.selectItem(this.chat_list[0]);
            }
          }
        });
      } else {
        getUserConversationListTen({
          user_id: getUserId(),
        }).then((res) => {
          if (res.data.code === 0) {
            this.chat_list = res.data.data.map((item) => {
              item.editStatus = false;
              return item;
            });
            if(flag){
              this.selectItem(this.chat_list[0]);
            }
          }
        });
      }
    },
    fetchData() {
      this.showAll = false;
      getUserConversationListTen({
        user_id: getUserId(),
      }).then((res) => {
        if (res.data.code === 0) {
          this.chat_list = res.data.data.map((item) => {
            item.editStatus = false;
            return item;
          });
          if (this.chat_list.length) {
            this.selectItem(this.chat_list[0]);
          } else {
            this.currentId = "";
            this.currentMessageList = [];
          }
        }
      });
    },
    viewAll() {
      this.showAll = true;
      this.refreshData();
    },
    scrollToBottom() {
      const dom = document.getElementById("messageContainer");
      this.$nextTick(() => {
        dom.scrollTop = dom.scrollHeight;
      });
    },
    selectItem(item) {
      if (this.isGenerate) return;
      this.loading = false;
      this.currentId = item._id.$id;
      this.currentItem = item;
      if (item.mode) {
        this.currentMode = item.mode;
      } else {
        this.currentMode = "GPT-3.5";
      }
      this.currentType = item.type;
      this.currentMessageList = item.data;
      this.scrollToBottom();
    },
    addNew() {
      updateConversation({
        id: "",
        user_id: getUserId(),
        name: "新的对话",
        data: [],
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("创建成功");
          this.refreshData(true);
        }
      });
    },
    updateData() {
      updateConversation({
        id: this.currentId,
        user_id: getUserId(),
        name: this.chat_list.find((item) => item._id.$id === this.currentId)
          .name,
        data: this.currentMessageList,
      }).then((res) => {
        if (res.data.code === 0) {
          this.refreshData();
        }
      });
    },
    changeToHtml(data) {
      if (data) {
        if (data.indexOf("```") !== -1) {
          const count = data.split("```").length - 1;
          if (count % 2 === 0) {
            return window.marked.parse(data);
          } else {
            return window.marked.parse(data + "\n\n```");
          }
        } else {
          return window.marked.parse(data);
        }
      } else {
        return "";
      }
    },
    initTitle() {
      const con = this.chat_list.find(
        (item) => item._id.$id === this.currentId
      );
      if (con.hasInit) {
        return;
      }
      sendConversation({
        messages: [
          {
            role: "user",
            content: `请为以下文字提供一个简短的标题（1. 标题的语言请和以下文字的语言保持一致；2. 请直接返回我标题，不要包括标题以外的任何附加文字）:${this.currentMessageList[0].content}`,
          },
        ],
      }).then((res) => {
        if (res.data.code === 0) {
          initConversationName({
            id: this.currentId,
            name: res.data.data.choices[0].message.content,
          }).then(() => {
            this.refreshData();
          });
        }
      });
    },
    async sendMessage(prefix) {
      if (!this.currentM) {
        this.$message.error("请输入内容");
        return;
      }
      if (this.isGenerate) return;

      // 设置模型
      if (!this.currentItem.mode) {
        setConversationMode({
          id: this.currentId,
          mode: this.currentMode,
        }).then(() => {
          this.currentItem.mode = this.currentMode;
        });
      }

      if (prefix) {
        this.currentM = prefix + this.currentM;
      }

      // 分一下模型
      if (
        this.currentMode === "GPT-3.5" ||
        this.currentMode === "GPT-4" ||
        this.currentMode === "moonshot-v1-8k" ||
        this.currentMode === "moonshot-v1-32k" ||
        this.currentMode === "moonshot-v1-128k" ||
        this.currentMode === "llama3" ||
        this.currentMode === "o1-preview" ||
        this.currentMode === "o1-mini"
      ) {
        // 分情况 是流式还是非流式
        if (this.isStream) {
          this.isGenerate = true;
          this.currentMessageList.push({
            role: "user",
            content: this.currentM,
          });
          this.scrollToBottom();
          this.currentM = "";
          this.currentMessageList.push({
            role: "assistant",
            content: "",
          });
          this.scrollToBottom();
          const that = this;
          const newIndex = this.currentIndex + 1;
          that.currentIndex = newIndex;
          const eventSource = fetchEventSource(
            "https://web-backend-sg.reachable-edu.com/AIChat/Test.php",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              openWhenHidden: true,
              body: JSON.stringify({
                model:
                  this.currentMode === "GPT-4"
                    ? "gpt-4"
                    : this.currentMode === "GPT-3.5"
                    ? "gpt-3.5-turbo"
                    : this.currentMode,
                messages: that.currentMessageList.slice(
                  0,
                  that.currentMessageList.length - 1
                ),
              }),
              onopen(response) {
                console.log("连接已建立", response);
              },
              onmessage(event) {
                if (event.data === "Connection closed") {
                  that.isGenerate = false;

                  //查看是否要计算token
                  if (
                    (that.currentMode === "GPT-4" ||
                      that.currentMode === "GPT-3.5") &&
                    that.isStream
                  ) {
                    that.computeToken();
                  }

                  return;
                }
                console.log("收到消息", JSON.stringify(event.data));
                if (newIndex === that.currentIndex) {
                  try {
                    const result = JSON.parse(event.data);
                    if (result.time && result.content) {
                      that.currentMessageList[
                        that.currentMessageList.length - 1
                      ].content += result.content;
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }
              },
              onclose() {
                if (newIndex === that.currentIndex) {
                  that.updateData();
                  that.initTitle();
                }
              },
            }
          );
        } else {
          this.loading = true;
          const content = this.currentM;
          this.currentM = "";
          sendConversationAndSave({
            id: this.currentId,
            model:
              this.currentMode === "GPT-4"
                ? "gpt-4"
                : this.currentMode === "GPT-3.5"
                ? "gpt-3.5-turbo"
                : this.currentMode,
            messages: [
              ...this.currentMessageList,
              { role: "user", content: content },
            ],
          })
            .then((res) => {
              if (res.data.status === "success") {
                const result = res.data.data;
                this.refreshData();
                if (this.currentId === result._id.$id) {
                  this.currentMessageList = result.data;
                  this.$nextTick(() => {
                    this.scrollToBottom();
                  });
                }
                // 生成标题
                if (result.hasInit) {
                  return;
                }
                sendConversation({
                  messages: [
                    {
                      role: "user",
                      content: `请为以下文字提供一个简短的标题（1. 标题的语言请和以下文字的语言保持一致；2. 请直接返回我标题，不要包括标题以外的任何附加文字）:${result.data[0].content}`,
                    },
                  ],
                }).then((res1) => {
                  if (res1.data.code === 0) {
                    initConversationName({
                      id: result._id.$id,
                      name: res1.data.data.choices[0].message.content,
                    }).then(() => {
                      this.refreshData();
                    });
                  }
                });
                // 生成标题结束
              } else {
                this.$message.error(res.data.data.message);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      } else if (this.currentMode === "DALL·E") {
        this.loading = true;
        getImage({
          prompt: this.currentM,
        })
          .then((res) => {
            if (res.data.code === 0) {
              this.currentMessageList.push({
                role: "user",
                content: this.currentM,
              });
              this.scrollToBottom();
              this.currentM = "";
              this.currentMessageList.push({
                role: "assistant",
                content: res.data.data.data[0].b64_json,
                mode: "image",
              });
              this.scrollToBottom();
              this.updateData();
              this.initTitle();
            } else {
              this.$message.error("生成失败");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.currentMode === "midjourney") {
        let result = await this.exeMidJModel(this.currentM);
        if (result) {
          let obj = this.currentMessageList[this.currentMessageList.length - 1];
          obj.imgUrl = result.url;
          obj.taskId = result.taskId;
          this.updateData();
          this.initTitle();
        }
      } else if (this.currentMode === "leonardo.ai") {
        let result = await this.exeLeonardoModel(this.currentM);
        if (result) {
          let obj = this.currentMessageList[this.currentMessageList.length - 1];
          obj.imgList = result.urls;
          obj.taskId = result.taskId;
          this.updateData();
          this.initTitle();
        }
      }
    },
    stopGenerate() {
      this.isGenerate = false;
      this.currentIndex++;
      this.updateData();
    },
    changeName(item) {
      initConversationName({
        id: item._id.$id,
        name: item.name,
      }).then(() => {
        this.$message.success("修改成功");
        this.refreshData();
        item.editStatus = false;
      });
    },
    remove(item) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeConversation({
          id: item._id.$id,
        }).then((res) => {
          this.$message.success("删除成功");
          this.refreshData();
        });
      });
    },
    removeContent(index) {
      this.$confirm("是否确认删除该条消息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.currentMessageList.splice(index, 1);
        this.updateData();
      });
    },
    copyContent(item) {
      var element = this.createElement(item.content);
      element.select();
      element.setSelectionRange(0, element.value.length);
      document.execCommand("copy");
      element.remove();
      this.$message.success("已复制到剪切板");
    },
    // 创建临时的输入框元素
    createElement(text) {
      var isRTL = document.documentElement.getAttribute("dir") === "rtl";
      var element = document.createElement("textarea");
      // 防止在ios中产生缩放效果
      element.style.fontSize = "12pt";
      // 重置盒模型
      element.style.border = "0";
      element.style.padding = "0";
      element.style.margin = "0";
      // 将元素移到屏幕外
      element.style.position = "absolute";
      element.style[isRTL ? "right" : "left"] = "-9999px";
      // 移动元素到页面底部
      const yPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      element.style.top = `${yPosition}px`;
      // 设置元素只读
      element.setAttribute("readonly", "");
      element.value = text;
      document.body.appendChild(element);
      return element;
    },
    setCor(item) {
      this.form.id = item._id.$id;
      this.form.coordinator_id = item.coordinator_id ? item.coordinator_id : [];
      this.showD = true;
    },
    confirmC() {
      updateConversationCoordinator({
        id: this.form.id,
        coordinator_id: this.form.coordinator_id,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("设置成功");
          this.showD = false;
          this.refreshData();
        }
      });
    },
    exportPDF() {
      document.body.className += "print";
      document.body.innerHTML =
        document.getElementById("messageContainer").innerHTML;
      window.print();
      location.reload();
    },
    changeText(e) {
      this.currentM = e;
    },
    changeText1(e) {
      this.currentM = this.templeContent + e;
    },
    handleCommand(e) {
      let prefix = this.prompts[e].content;
      this.sendMessage(prefix);
    },
    archive(item) {
      this.$confirm("是否确认归档?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        archiveConversation({
          id: item._id.$id,
        }).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("归档成功");
            this.refreshData();
          }
        });
      });
    },
    editContent(item, index) {
      this.currentContentString = item.content;
      this.currentContentIndex = index;
      this.showContent = true;
    },
    confirmEditContent() {
      this.currentMessageList[this.currentContentIndex].content =
        this.currentContentString;
      this.updateData();
      this.showContent = false;
    },
    addPrompt() {
      this.prompts.push({ name: "", content: "" });
    },
    exeMidJModel(prompt) {
      return new Promise((resolve) => {
        this.loading = true;
        getMidJourney({
          prompt,
        }).then(async (res) => {
          this.loading = false;
          this.progress = "0%";
          this.currentMessageList.push({
            role: "user",
            content: this.currentM,
          });
          this.currentM = "";
          let obj = {
            role: "assistant",
            content: "",
            mode: "imageMid",
            imgUrl: "",
            taskId: "",
          };
          this.currentMessageList.push(obj);
          this.scrollToBottom();
          let messageId = res.data.data.result;
          let url = await this.getResult(messageId);
          resolve({
            taskId: messageId,
            url,
          });
        });
      });
    },
    getResult(messageId) {
      let flag = false;
      //写一个promise函数，循环调用查询结果的接口，如果获取到结果就resolve，否则继续查询
      return new Promise((resolve) => {
        let timer = setInterval(() => {
          if (!flag) {
            flag = true;
            getMidJourneyResult({
              taskId: messageId,
            }).then((res) => {
              if (res.data.code === 0) {
                flag = false;
                let result = res.data.data;
                this.progress = result.progress;
                if (result.status === "SUCCESS") {
                  clearInterval(timer);
                  resolve(result.image_qiniu_url);
                }
              } else {
                clearInterval(timer);
                resolve();
              }
            });
          }
        }, 3000);
      });
    },
    exeMidJModelChange(action, index, taskId) {
      return new Promise((resolve) => {
        this.loading = true;
        changeImage({
          action,
          index,
          taskId,
        }).then(async (res) => {
          this.loading = false;
          let result = res.data.data;
          if (result.code === 1) {
            this.progress = "0%";
            let obj = {
              role: "assistant",
              content: "",
              mode: "imageMid",
              imgUrl: "",
              taskId: "",
            };
            this.currentMessageList.push(obj);
            this.scrollToBottom();
            let messageId = res.data.data.result;
            let url = await this.getResult(messageId);
            resolve({
              taskId: messageId,
              url,
            });
          } else {
            this.$message.error(result.description);
            resolve(false);
          }
        });
      });
    },
    async toEditImage(action, index, taskId) {
      let result = await this.exeMidJModelChange(action, index, taskId);
      if (result) {
        let obj = this.currentMessageList[this.currentMessageList.length - 1];
        obj.imgUrl = result.url;
        obj.taskId = result.taskId;
        this.updateData();
        this.initTitle();
      }
    },
    exeLeonardoModel(prompt) {
      return new Promise((resolve) => {
        this.loading = true;
        getLeonardoAI({
          prompt,
          model: this.leoModel,
          presetStyle: this.leoStyle,
        }).then(async (res) => {
          this.loading = false;
          this.currentMessageList.push({
            role: "user",
            content: this.currentM,
          });
          this.currentM = "";
          let obj = {
            role: "assistant",
            content: "",
            mode: "imageLeo",
            imgList: "",
            taskId: "",
          };
          this.currentMessageList.push(obj);
          this.scrollToBottom();
          let messageId = res.data.data.sdGenerationJob.generationId;
          let urls = await this.getLeoResult(messageId);
          resolve({
            taskId: messageId,
            urls,
          });
        });
      });
    },
    getLeoResult(messageId) {
      let flag = false;
      //写一个promise函数，循环调用查询结果的接口，如果获取到结果就resolve，否则继续查询
      return new Promise((resolve) => {
        let timer = setInterval(() => {
          if (!flag) {
            flag = true;
            getLeonardoAIResult({
              taskId: messageId,
            }).then((res) => {
              if (res.data.code === 0) {
                flag = false;
                let result = res.data.data;
                if (
                  result.generations_by_pk &&
                  result.generations_by_pk.status === "COMPLETE"
                ) {
                  clearInterval(timer);
                  resolve(result.generations_by_pk.qiniu_images);
                }
              } else {
                clearInterval(timer);
                resolve();
              }
            });
          }
        }, 3000);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-ai {
  width: 100%;
  height: 100%;
  background-color: #fff;
  height: 100vh;
  display: flex;
  color: #ddd;
  .aside {
    width: 260px;
    height: 100%;
    background: rgb(32, 33, 35);
    overflow-y: scroll;
    overflow-x: hidden;
    .new-btn {
      width: 244px;
      height: 45px;
      line-height: 45px;
      padding-left: 20px;
      box-sizing: border-box;
      cursor: pointer;
      margin: 10px auto;
      border-radius: 8px;
      border: 1px solid #555;
    }
    .new-btn:hover {
      background-color: hsla(240, 9%, 59%, 0.1);
    }
    .chatListItem {
      width: 244px;
      height: 44px;
      display: flex;
      align-items: center;
      line-height: 44px;
      border-radius: 8px;
      box-sizing: border-box;
      margin: 0 auto;
      padding-left: 10px;
      cursor: pointer;
      position: relative;
      .txt {
        width: 190px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .archive-icon {
        display: none;
        position: absolute;
        right: 50px;
        top: 14px;
      }
      .archive-icon:hover {
        color: #fff;
      }
      .edit-icon {
        display: none;
        position: absolute;
        right: 70px;
        top: 14px;
      }
      .edit-icon:hover {
        color: #fff;
      }
      .del-icon {
        display: none;
        position: absolute;
        right: 30px;
        top: 14px;
      }
      .del-icon:hover {
        color: #fff;
      }
      .set-icon {
        display: none;
        position: absolute;
        right: 10px;
        top: 14px;
      }
      .set-icon:hover {
        color: #fff;
      }
    }
    .chatListItem:hover {
      .txt {
        width: 120px;
      }
      .archive-icon {
        display: block;
      }
      .del-icon {
        display: block;
      }
      .edit-icon {
        display: block;
      }
      .set-icon {
        display: block;
      }
    }
    .chatListItem.selected {
      background: #343541;
    }
  }
  .main {
    flex: 1;
    overflow: hidden;
    position: relative;
    .topLine {
      height: 50px;
      border-bottom: 1px solid #ddd;
      display: flex;
      align-items: center;
      padding: 0 10px;
      color: #333;
    }
    .messageContainer {
      width: 100%;
      height: calc(100% - 180px);
      overflow-y: scroll;
      white-space: pre-wrap;
      line-height: 1.75;
      .messageItem {
        padding: 20px 0;
        color: #333;
        border-bottom: 1px solid #ddd;
        min-height: 91px;
        position: relative;
        .messageItem1 {
          width: 70%;
          margin: 0 auto;
        }
        .editBtn {
          position: absolute;
          top: 10px;
          right: 166px;
          display: none;
        }
        .copyBtn {
          position: absolute;
          top: 10px;
          right: 96px;
          display: none;
        }
        .removeBtn {
          position: absolute;
          top: 10px;
          right: 10px;
          display: none;
        }
      }
      .messageItem:hover {
        .editBtn {
          display: block;
        }
        .copyBtn {
          display: block;
        }
        .removeBtn {
          display: block;
        }
      }
      .grey {
        background: #eee;
      }
    }
    .inputC {
      text-align: center;
      width: 100%;
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      .inner {
        width: 72%;
      }
    }
    .myInput {
      box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.1);
    }
  }
  .imageEditBtn {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
}
</style>
<style lang="scss">
.print {
  width: 100%;
  .messageItem {
    padding: 20px 0;
    color: #333;
    border-bottom: 1px solid #ddd;
    min-height: 91px;
    position: relative;
    .messageItem1 {
      width: 70%;
      margin: 0 auto;
    }
    .editBtn {
      position: absolute;
      top: 10px;
      right: 168px;
      display: none;
    }
    .copyBtn {
      position: absolute;
      top: 10px;
      right: 96px;
      display: none;
    }
    .removeBtn {
      position: absolute;
      top: 10px;
      right: 10px;
      display: none;
    }
  }
  .grey {
    background: #eee;
  }
}
.messageItem1 pre {
  width: 100%;
  padding: 10px;
  background-color: #23241f;
  border-radius: 6px;
  color: #f8f8f2;
  box-sizing: border-box;
  overflow-x: auto;
}
pre code.hljs {
  display: block;
  overflow-x: auto;
  padding: 1em;
}
code.hljs {
  padding: 3px 5px;
}
.hljs {
  background: #23241f;
  color: #f8f8f2;
}
.hljs-subst,
.hljs-tag {
  color: #f8f8f2;
}
.hljs-emphasis,
.hljs-strong {
  color: #a8a8a2;
}
.hljs-bullet,
.hljs-link,
.hljs-literal,
.hljs-number,
.hljs-quote,
.hljs-regexp {
  color: #ae81ff;
}
.hljs-code,
.hljs-section,
.hljs-selector-class,
.hljs-title {
  color: #a6e22e;
}
.hljs-strong {
  font-weight: 700;
}
.hljs-emphasis {
  font-style: italic;
}
.hljs-attr,
.hljs-keyword,
.hljs-name,
.hljs-selector-tag {
  color: #f92672;
}
.hljs-attribute,
.hljs-symbol {
  color: #66d9ef;
}
.hljs-class .hljs-title,
.hljs-params,
.hljs-title.class_ {
  color: #f8f8f2;
}
.hljs-addition,
.hljs-built_in,
.hljs-selector-attr,
.hljs-selector-id,
.hljs-selector-pseudo,
.hljs-string,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
  color: #e6db74;
}
.hljs-comment,
.hljs-deletion,
.hljs-meta {
  color: #75715e;
}
</style>
